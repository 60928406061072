<template>
  <div>
    <sc-rich-text :field="fields.stepContent" />
    <product-tile
      :productIcon="fields.houseIcon"
      :productTitle="insuranceStreetAddress"
      :productBody="insuranceSuburbState + insuranceSuburbStateAndPropertyDtls"
    />

    <div class="form-elements-container" @click="hideCustomTooltips">
      <sc-rich-text :field="fields.propertyDetailsSectionHeading" />
      <br />
      <FormulateForm v-model="formValues" @submit="onSubmit">
        <FormulateInput
          v-if="!isUnitOrFlat"
          :label="modifiedNumOfStoreysLabel"
          name="numOfStoreys"
          key="numOfStoreys"
          type="radio"
          :options="numOfStoreys"
          validation="required"
          :validation-messages="getValidationMessage('numOfStoreysValidationMessage')"
          suffixPosition="after"
          helpPosition="after"
          @input="toggleNumOfStoreysTooltip(true)"
        >
          <!-- alter the suffix slot with some html -->
          <template #suffix>
            <button class="questionmark-icon step-3__question-mark" type="button" @click.stop="toggleNumOfStoreysTooltip"></button>
          </template>

          <template #help>
            <div v-if="isNumOfStoreysTooltipVisible" class="step-3__custom-tooltip-container">
              <span class="step-3_tooltip-arrow"></span>
              <div class="step-3__custom-tooltip-content-container">
                <div v-html="fields.numOfStoreysTooltip.value" class="step-3__custom-tooltip"></div>
                <button class="tooltip-close-button" @click.stop="toggleNumOfStoreysTooltip"></button>
              </div>
            </div>
          </template>
        </FormulateInput>

        <FormulateInput
          v-if="!isUnitOrFlat"
          :label="fields.propertyElevatedLabel.value"
          name="propertyElevated"
          key="propertyElevated"
          type="radio"
          :options="[
            { label: 'Yes', value: 'Yes', id: 'property-elevated-yes_last-child' },
            { label: 'No', value: 'No', id: 'property-elevated-no_last-child' },
          ]"
          :tooltip="getTooltip('property-elevated-tooltip-id', 'propertyElevatedTooltip')"
          @input="showTooltip('property-elevated-tooltip-id')"
          validation="required"
          :validation-messages="getValidationMessage()"
        />

        <FormulateInput
          v-if="isUnitOrFlat"
          id="lowestOccupiedFloor"
          name="lowestOccupiedFloor"
          :options="lowestOccupiedFloor"
          type="selectbox"
          placeholder="Select"
          :label="modifiedLowestOccupiedFloorLabel"
          validation="required"
          :validation-messages="getValidationMessage('lowestOccupiedFloorValidationMessage')"
          :tooltip="{
            content: fields.lowestOccupiedFloorTooltip.value,
            tooltipId: 'lowestOccupiedFloor-tooltip-id',
          }"
        >
        </FormulateInput>

        <div class="step-3__divider" v-if="showWindowQuestions"></div>

        <FormulateInput
          v-if="showGarageQuestions"
          :label="fields.garageRollerDoorsLabel.value"
          name="garageRollerDoors"
          key="garageRollerDoors"
          type="radio"
          :options="getYesNoOptions('garage-roller-doors_last-child')"
          :label-class="['no-margin']"
          :help-class="['no-margin', 'short-width']"
          :help="fields.garageRollerDoorsCaptionLabel.value"
          helpPosition="after"
          validation="required"
          :validation-messages="genericValidationErrorMessage"
        />

        <FormulateInput
          v-if="showGarageQuestions && showGarageBracingQuestion"
          :label="fields.garageRollerDoorsBracingLabel.value"
          name="garageRollerDoorsBracing"
          key="garageRollerDoorsBracing"
          type="radio"
          :options="getYesNoOptions('garage-roller-doors-bracing_last-child', true)"
          :tooltip="getTooltip('garage-roller-tooltip-id', 'garageRollerDoorsBracingTooltip')"
          @input="showTooltip('garage-roller-tooltip-id')"
          validation="required"
          :validation-messages="genericValidationErrorMessage"
        />

        <FormulateInput
          v-if="showWindowQuestions"
          :label="fields.windowWeatherProtectionLabel.value"
          name="windowWeatherProtection"
          key="windowWeatherProtection"
          type="radio"
          :options="getYesNoOptions('window-weather-protection_last-child')"
          :label-class="['no-margin']"
          :help-class="['no-margin', 'short-width']"
          :help="fields.windowWeatherProtectionCaptionLabel.value"
          helpPosition="after"
          validation="required"
          :validation-messages="genericValidationErrorMessage"
        />

        <FormulateInput
          v-if="showWindowQuestions && showWindowAllQuestion"
          :label="fields.windowWeatherProtectionAllWindowsLabel.value"
          name="windowWeatherProtectionAll"
          key="windowWeatherProtectionAll"
          type="radio"
          :options="getYesNoOptions('window-weather-protection-all_last-child', true)"
          :tooltip="getTooltip('window-weather-protection-all-tooltip-id', 'windowWeatherProtectionAllWindowsTooltip')"
          @input="showTooltip('window-weather-protection-all-tooltip-id')"
          validation="required"
          :validation-messages="genericValidationErrorMessage"
        />

        <FormulateInput
          v-if="showRoofQuestions"
          :label="fields.roofReplacedLabel.value"
          name="roofReplacedUpgraded"
          key="roofReplacedUpgraded"
          type="radio"
          :options="[
            { label: 'Yes, upgraded', value: 'Upgrades' },
            { label: 'Yes, replaced', value: 'Replacement' },
            { label: 'No', value: 'No' },
            { label: 'Not sure', value: 'Unknown', id: 'roof-replaced-upgraded_last-child' },
          ]"
          helpPosition="after"
          :label-class="['no-margin']"
          validation="required"
          :validation-messages="genericValidationErrorMessage"
        >
          <!-- alter the help slot with some html -->
          <template #help>
            <div class="step-3__roof-replaced-upgraded-columns">
              <div class="step-3__roof-replaced-upgraded-caption1">{{ fields.roofReplacedCaptionLabel.value }}</div>
              <div v-html="fields.roofReplacedHelp.value"></div>
            </div>
          </template>
        </FormulateInput>

        <FormulateInput
          v-if="showRoofQuestions && showRoofStructureTieDownQuestion"
          :label="modifiedStructureTieDownLabel"
          name="roofStructureTieDown"
          key="roofStructureTieDown"
          type="radio"
          :options="getYesNoOptions('roof-structure-tie-down_last-child', true)"
          validation="required"
          :validation-messages="genericValidationErrorMessage"
        />

        <div class="warning-note step-3__warning-note" v-show="noChangesMade">
          <sc-text :field="fields.noUpdatesMadeMessage"></sc-text>
        </div>

        <div class="form-buttons">
          <FormulateInput type="button" :name="fields.previousButtonText.value" input-class="btn btn-secondary" outer-class="" @click="goBack" />

          <FormulateInput type="submit" :name="fields.nextButtonText.value" input-class="btn btn-primary" outer-class="" :disabled="noChangesMade" />
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import RACQFormsMixins from '../../../foundation/forms/mixins/RACQFormsMixins';
import ProductTile from '../../../foundation/forms/ProductTile.vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import { hideAllPoppers } from 'floating-vue';

export default {
  name: 'Step3CycloneInsurance',
  components: {
    ScRichText: RichText,
    ProductTile,
    ScText: Text,
  },
  data() {
    return {
      formValues: {},
      isNumOfStoreysTooltipVisible: false,
      isLowestOccupiedFloorTooltipVisible: false,
      showWindowQuestions: false,
      showGarageQuestions: false,
      showRoofQuestions: false,
      originalValues: {},
      idCurrentTooltip: null,
      enableToolTips: false,
    };
  },
  watch: {
    insuranceProduct() {
      // setup whether to show specific questions
      if (this.isCycloneCovered === true) {
        if (['BoardingHome', 'ManufTransportableHome', 'Storage'].includes(this.insuranceProduct?.insurancePolicy?.coverages[0]?.propertyType) == false) {
          this.showWindowQuestions = true;
          if (this.insuranceProduct?.insurancePolicy?.coverages[0]?.yearBuilt < 1982) {
            this.showRoofQuestions = true;
          }
          if (this.insuranceProduct?.insurancePolicy?.coverages[0]?.yearBuilt < 2012) {
            this.showGarageQuestions = true;
          }
        }
      }

      // setup the default values
      this.formValues = {
        numOfStoreys: this.insuranceProduct?.insurancePolicy?.coverages[0]?.numOfStoreys,
        propertyElevated:
          this.insuranceProduct?.insurancePolicy?.coverages[0]?.propertyElevated == true
            ? 'Yes'
            : this.insuranceProduct?.insurancePolicy?.coverages[0]?.propertyElevated == false
            ? 'No'
            : undefined,
        lowestOccupiedFloor:
          this.insuranceProduct?.insurancePolicy?.coverages[0]?.occupiedFloor === 'SecondFloorOrAbove'
            ? undefined
            : this.insuranceProduct?.insurancePolicy?.coverages[0]?.occupiedFloor,
        garageRollerDoors:
          this.showGarageQuestions === true
            ? this.insuranceProduct?.insurancePolicy?.coverages[0]?.rollerDoor == true
              ? 'Yes'
              : this.insuranceProduct?.insurancePolicy?.coverages[0]?.rollerDoor == false
              ? 'No'
              : undefined
            : undefined,
        garageRollerDoorsBracing:
          this.showGarageQuestions === true && this.insuranceProduct?.insurancePolicy?.coverages[0]?.rollerDoor === true
            ? this.insuranceProduct?.insurancePolicy?.coverages[0]?.rolleDoorBracing
            : undefined,
        windowWeatherProtection:
          this.showWindowQuestions === true
            ? this.insuranceProduct?.insurancePolicy?.coverages[0]?.windowProtection == null
              ? undefined
              : ['Yes', 'Unknown'].includes(this.insuranceProduct?.insurancePolicy?.coverages[0]?.windowProtection)
              ? 'Yes'
              : 'No'
            : undefined,
        windowWeatherProtectionAll:
          this.insuranceProduct?.insurancePolicy?.coverages[0]?.windowProtection === 'Yes' ||
          this.insuranceProduct?.insurancePolicy?.coverages[0]?.windowProtection === 'Unknown'
            ? this.insuranceProduct?.insurancePolicy?.coverages[0]?.windowProtection
            : undefined, //currently policy center doesn't store a separate value for this field
        roofReplacedUpgraded: this.showRoofQuestions === true ? this.insuranceProduct?.insurancePolicy?.coverages[0]?.roofReplacement : undefined, // cater for business decision to have requirement of sending a value from IQ to PC even though question is not applicable
        roofStructureTieDown:
          this.showRoofQuestions === true && ['Upgrades', 'Replacement'].includes(this.insuranceProduct?.insurancePolicy?.coverages[0]?.roofReplacement)
            ? this.insuranceProduct?.insurancePolicy?.coverages[0]?.tiedownupgrade
            : undefined, // cater for business decision to have requirement of sending a value from IQ to PC even though question is not applicable
      };

      this.originalValues = { ...this.formValues }; //create a copy of the original values
    },
  },
  computed: {
    ...mapState({
      insuranceProduct: (state) => state.cycloneInsuranceModule.insuranceProduct,
      isCycloneCovered: (state) => state.cycloneInsuranceModule.isCycloneCovered,
      numOfStoreys: (state) => state.cycloneInsuranceModule.numOfStoreys,
      lowestOccupiedFloor: (state) => state.cycloneInsuranceModule.lowestOccupiedFloor,
      stepData: (state) => state.cycloneInsuranceModule.step3Data,
      reviewItemOrder: (state) => state.cycloneInsuranceModule.reviewItemOrder,
    }),
    ...mapGetters({
      insuranceStreetAddress: 'cycloneInsuranceModule/insuranceStreetAddress',
      insuranceSuburbState: 'cycloneInsuranceModule/insuranceSuburbState',
      insuranceSuburbStateAndPropertyDtls: 'cycloneInsuranceModule/insuranceSuburbStateAndPropertyDtls',
      buildingTypeDescription: 'cycloneInsuranceModule/buildingTypeDescription',
    }),
    modifiedNumOfStoreysLabel() {
      return this.fields.numOfStoreysLabel?.value.replace('${propertyType}', this.buildingTypeDescription?.toLowerCase());
    },
    modifiedLowestOccupiedFloorLabel() {
      return this.fields.lowestOccupiedFloorLabel?.value.replace('${propertyType}', this.buildingTypeDescription?.toLowerCase());
    },
    modifiedStructureTieDownLabel() {
      const currentLabel = this.fields.roofIncludeStructureTieDownLabel?.value;
      return currentLabel.replace('${upgradeOrReplace}', this.formValues?.roofReplacedUpgraded?.toLowerCase());
    },
    genericValidationErrorMessage() {
      return { required: this.fields.requiredValidationMessage.value };
    },
    isUnitOrFlat() {
      return ['Unit', 'Flat'].includes(this.insuranceProduct?.insurancePolicy?.coverages[0]?.propertyType);
    },
    showWindowAllQuestion() {
      return this.formValues.windowWeatherProtection === 'Yes';
    },
    showGarageBracingQuestion() {
      return this.formValues.garageRollerDoors === 'Yes';
    },
    showRoofStructureTieDownQuestion() {
      return this.formValues.roofReplacedUpgraded === 'Upgrades' || this.formValues.roofReplacedUpgraded === 'Replacement';
    },
    noChangesMade() {
      //HACK: Remove manually need to investigate why propertyElevated keeps getting added when it's not selected
      if (this.formValues.propertyElevated === '') {
        delete this.formValues.propertyElevated;
      }

      return JSON.stringify(this.formValues) != JSON.stringify(this.originalValues) ? null : 'disable';
    },
  },
  methods: {
    ...mapActions('cycloneInsuranceModule', ['updateFormValues']),
    performPreProcessing() {
      this.hideCustomTooltips();
      hideAllPoppers();
      this.enableToolTips = true;
    },
    onSubmit() {
      hideAllPoppers();
      //customizations and add some extra data for subsequent steps that the vue formulate form model doesn't have
      const finalItemsToReview = [];
      this.reviewItemOrder.forEach((key) => {
        let value = this.formValues[key];
        let displayValue = value?.toString().charAt(0).toUpperCase() + value?.toString().substring(1);

        if (key === 'numOfStoreys') {
          displayValue = this.numOfStoreys.find((item) => item.value === value)?.label;
        }

        if (key === 'lowestOccupiedFloor') {
          displayValue = this.lowestOccupiedFloor.find((item) => item.value === value)?.label;
        }

        // specific logic cause we don't store the all windows question in policycenter
        // so we need to merge the result
        if (key === 'windowWeatherProtection') {
          const windowProtectionAllValue = this.formValues['windowWeatherProtectionAll']; //check the all windows question

          if (['Yes', 'Unknown', 'No'].includes(windowProtectionAllValue)) {
            value = windowProtectionAllValue;
            displayValue = windowProtectionAllValue;
          } else {
            if (['Yes', 'No'].includes(value) != true) {
              //check the window protection question
              value = null;
            }
          }
        }

        if (value === 'Unknown') {
          displayValue = 'Not sure';
        }

        if (value === 'Upgrades') {
          displayValue = 'Upgrade';
        }

        if (value) {
          finalItemsToReview.push({ key: key, displayValue: displayValue, value: value, enableEditValue: true });
        }
      });

      this.updateFormValues({ stepValues: finalItemsToReview, stepNumber: 3 });
      this.$emit('go-next');
    },
    goBack() {
      this.$emit('go-back');
    },
    hideCustomTooltips() {
      this.isNumOfStoreysTooltipVisible = false;
      this.isLowestOccupiedFloorTooltipVisible = false;
    },
    toggleNumOfStoreysTooltip(show = false) {
      hideAllPoppers();
      if (show === true) {
        this.isNumOfStoreysTooltipVisible = show;
        return;
      }
      this.isNumOfStoreysTooltipVisible = !this.isNumOfStoreysTooltipVisible;
    },
    toggleLowestOccupiedFloorTooltip(show = false) {
      hideAllPoppers();
      if (show === true) {
        this.isLowestOccupiedFloorTooltipVisible = show;
        return;
      }
      this.isLowestOccupiedFloorTooltipVisible = !this.isLowestOccupiedFloorTooltipVisible;
    },
    getTooltip(id, fieldName) {
      const toolTipText = this.fields[fieldName]?.value;
      return {
        content: toolTipText,
        tooltipId: id,
        autoHide: false,
      };
    },
    showTooltip(id) {
      if (!this.enableToolTips) return;

      // hide the other tooltips
      if (this.idCurrentTooltip != id) {
        hideAllPoppers();
        this.idCurrentTooltip = id;
      }

      // show tooltip for this field
      const tooltipContents = document.querySelectorAll(`#${id}`);
      if (tooltipContents?.length > 0) {
        // there are multiple instances of the tooltip markup (one for each radio option)
        const tooltipButtonToTrigger = tooltipContents[tooltipContents.length - 1];
        const tooltipIsShown = tooltipButtonToTrigger.parentElement.parentElement.classList.contains('v-popper--shown');
        if (!tooltipIsShown) {
          tooltipButtonToTrigger.click();
        }
      }
    },
    getValidationMessage(fieldName = null) {
      if (fieldName) {
        return { required: this.fields[fieldName]?.value };
      }
      return this.genericValidationErrorMessage;
    },
    getYesNoOptions(idLastItem, addUnknown = false) {
      const options = [];
      options.push({ label: 'Yes', value: 'Yes' });
      options.push({ label: 'No', value: 'No' });
      if (addUnknown) {
        options.push({ label: 'Not sure', value: 'Unknown', id: idLastItem });
      }
      return options;
    },
  },
  mixins: [RACQFormsMixins],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
#property-elevated-yes_last-child_label {
  background-image: url('~@/assets/img/icons/house1m_notselected.svg');
}

#property-elevated-no_last-child_label {
  background-image: url('~@/assets/img/icons/house0m_notselected.svg');
  margin-left: 20px;
  border: 2px solid $racq-navy !important;
}

#property-elevated-yes_last-child_label,
#property-elevated-no_last-child_label {
  background-position: center calc(100% - 20px);
  background-repeat: no-repeat;
  height: 90px;
  width: 90px;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 4px;
}

.formulate-input-group-item[data-has-value='true'] > .formulate-input-wrapper > #property-elevated-yes_last-child_label {
  background-image: url('~@/assets/img/icons/house1m_selected.svg');
}

.formulate-input-group-item[data-has-value='true'] > .formulate-input-wrapper > #property-elevated-no_last-child_label {
  background-image: url('~@/assets/img/icons/house0m_selected.svg');
  border: 2px solid $racq-navy !important;
}

#num-of-storeys-tooltip-id {
  display: flex;
}

.step-3__roof-replaced-upgraded-columns {
  display: flex;

  .step-3__roof-replaced-upgraded-caption1 {
    width: 25%;
    margin-right: 67px;
  }
}

.step-3__custom-tooltip-container {
  display: flex;
  flex-direction: column;
  color: $racq-navy;
}

.step-3_tooltip-arrow {
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 50px solid $racq-blue-background;
  border-width: 0 10px 18px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  left: -75px;
}

.step-3__custom-tooltip-content-container {
  display: flex;
  width: 100%;
  background-color: $racq-blue-background;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 10px;

  @media (min-width: $breakpoint-xl) {
    margin-left: auto;
    width: 66%;
  }
}

// TODO move out to shared
.tooltip-close-button {
  background: transparent url('~@/assets/img/icons/close-icon.svg');
  background-repeat: no-repeat;
  display: flex;
  min-width: 1.875rem;
  height: 1.875rem;
  border: none;
  padding: 0;
  outline: none;
  top: -0.5rem;
  right: -0.5rem;
  position: relative;

  &:hover {
    background: transparent url('~@/assets/img/icons/close-icon-hover.svg');
  }
}

.step-3__divider {
  border-bottom: 1px solid $light-grey;
  margin: 40px 0px;
}

.step-3__warning-note {
  margin-top: 40px;
  margin-bottom: 40px;
}

.step-3__close {
  width: 16px;
  height: 16px;
}

.questionmark-icon.step-3__question-mark {
  margin-left: 0.5rem;
  outline: none;
}
</style>
